// src/store/store.js
import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counterSlice";
import userReducer from "./slices/userSlice";
import cartSlice from "./slices/cartSlice";
import notificationSlice from "./slices/notificationSlice";

const store = configureStore({
  reducer: {
    counter: counterReducer,
    user: userReducer,
    cart: cartSlice,
    notification:notificationSlice
  },
});

export default store;
