import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/slices/userSlice";
import Default from "./Default";
import Addresses from "./Addresses";
import AccountDetails from "./AccountDetails";
import Orders from "./Orders";

export default function User() {
  const dispatch = useDispatch();
  const logoutUser = () => {
      dispatch(logout());
      localStorage.removeItem("UserToken");
      window.location.reload();
  };

  return (
    <div className="pt-140">
      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
        data-bg="img/bg/9.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  <h1 className="section-title white-color">My Account</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">Home</a>
                    </li>
                    <li>My Account</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="liton__wishlist-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              {/* <!-- PRODUCT TAB AREA START --> */}
              <div className="ltn__product-tab-area">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="ltn__tab-menu-list mb-50">
                        <div className="nav">
                          {/* <a
                            className="active show"
                            data-bs-toggle="tab"
                            href="#liton_tab_1_1"
                          >
                            Dashboard <i className="fas fa-home"></i>
                          </a> */}
                          <a  className="active show" data-bs-toggle="tab" href="#liton_tab_1_2">
                            Orders <i className="fas fa-file-alt"></i>
                          </a>
                          {/* <a data-bs-toggle="tab" href="#liton_tab_1_3">
                            Downloads <i className="fas fa-arrow-down"></i>
                          </a> */}
                          <a data-bs-toggle="tab" href="#liton_tab_1_4">
                            Address <i className="fas fa-map-marker-alt"></i>
                          </a>
                          <a data-bs-toggle="tab" href="#liton_tab_1_5">
                            Account Details <i className="fas fa-user"></i>
                          </a>
                          <a role="button" data-bs-toggle="modal" data-bs-target="#logout_modal">
                            Logout <i className="fas fa-sign-out-alt"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="tab-content">
                        {/* <div
                          className="tab-pane fade active show"
                          id="liton_tab_1_1"
                        >
                          <Default />
                        </div> */}
                        <div className="tab-pane fade active show" id="liton_tab_1_2">
                          <Orders />
                        </div>
                        {/* <div className="tab-pane fade" id="liton_tab_1_3">
                          <div className="ltn__myaccount-tab-content-inner">
                            <div className="table-responsive">
                              <table className="table">
                                <thead>
                                  <tr>
                                    <th>Product</th>
                                    <th>Date</th>
                                    <th>Expire</th>
                                    <th>Download</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>Carsafe - Car Service PSD Template</td>
                                    <td>Nov 22, 2020</td>
                                    <td>Yes</td>
                                    <td>
                                      <a href="#">
                                        <i className="far fa-arrow-to-bottom mr-1"></i>{" "}
                                        Download File
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Carsafe - Car Service HTML Template</td>
                                    <td>Nov 10, 2020</td>
                                    <td>Yes</td>
                                    <td>
                                      <a href="#">
                                        <i className="far fa-arrow-to-bottom mr-1"></i>{" "}
                                        Download File
                                      </a>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      Carsafe - Car Service WordPress Theme
                                    </td>
                                    <td>Nov 12, 2020</td>
                                    <td>Yes</td>
                                    <td>
                                      <a href="#">
                                        <i className="far fa-arrow-to-bottom mr-1"></i>{" "}
                                        Download File
                                      </a>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div> */}
                        <div className="tab-pane fade" id="liton_tab_1_4">
                          <Addresses />
                        </div>
                        <div className="tab-pane fade" id="liton_tab_1_5">
                          <AccountDetails />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- PRODUCT TAB AREA END --> */}
            </div>
          </div>
        </div>
      </div>

           {/* modal start logout */}
           <div class="ltn__modal-area ltn__add-to-cart-modal-area">
        <div class="modal fade" id="logout_modal" tabindex="-1">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                         <div class="ltn__quick-view-modal-inner">
                             <div class="modal-product-item">
                                <div class="row">
                                    <div class="col-12">
                                        {/* <div class="modal-product-img">
                                            <img src="img/product/1.png" alt="#"/>
                                        </div> */}
                                         <div class="modal-product-info">
                                            <h5><a href="product-details.html">Confirmation</a></h5>
                                            <p class="added-cart"><i class="fa fa-check-circle"></i>  Do you want to logout..?</p>
                                            <div class="btn-wrapper">
                                                <a href="javascript:void(0);" onClick={logoutUser} class="theme-btn-1 btn btn-effect-1">Logout</a>
                                                <a href="javascript:void(0);" class="theme-btn-2 btn btn-effect-2" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                                            </div>
                                         </div>
                                         <div class="additional-info d-none">
                                            <p>We want to give you <b>10% discount</b> for your first order, <br/>  Use discount code at checkout</p>
                                            <div class="payment-method">
                                                <img src="img/icons/payment.png" alt="#"/>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                             </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
  );
}
