import React from "react";

export default function About() {
  return (
    <div className="pt-140">
      <div
        className="ltn__breadcrumb-area ltn__breadcrumb-area-2 ltn__breadcrumb-color-white bg-overlay-theme-black-90 bg-image"
        data-bg="img/bg/5.jpg"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="ltn__breadcrumb-inner ltn__breadcrumb-inner-2 justify-content-between">
                <div className="section-title-area ltn__section-title-2">
                  <h1 className="section-title white-color">About Us</h1>
                </div>
                <div className="ltn__breadcrumb-list">
                  <ul>
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>About Us</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ltn__about-us-area pt-120--- pb-120">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 align-self-center">
              <div className="about-us-img-wrap about-img-left">
                <img src="https://devapi.mvp.atopd.in/Assets/1.png" alt="About Us Image" />
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="about-us-info-wrap">
                <div className="section-title-area ltn__section-title-2">
                  <h6 className="section-subtitle ltn__secondary-color">
                    Know More About Shop
                  </h6>
                  <h1 className="section-title">
                    Trusted Organic <br className="d-none d-md-block" /> Food
                    Store
                  </h1>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore
                  </p>
                </div>
                <p>
                  sellers who aspire to be good, do good, and spread goodness.
                  We democratic, self-sustaining, two-sided marketplace which
                  thrives on trust and is built on community and quality
                  content.
                </p>
                <div className="about-author-info d-flex">
                  <div className="author-name-designation  align-self-center">
                    <h4 className="mb-0">Jerry Henson</h4>
                    <small>/ Shop Director</small>
                  </div>
                  <div className="author-sign">
                    <img src="img/icons/icon-img/author-sign.png" alt="#" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__feature-area section-bg-1 pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2 text-center">
                <h6 className="section-subtitle ltn__secondary-color">
                  // features //
                </h6>
                <h1 className="section-title">
                  Why Choose Us<span>.</span>
                </h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <img src="img/icons/icon-img/21.png" alt="#" />
                    </span>
                  </div>
                  <h3>
                    <a href="service-details.html">All Kind Brand</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p>
                    Lorem ipsum dolor sit ame it, consectetur adipisicing elit,
                    sed do eiusmod te mp or incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <img src="img/icons/icon-img/22.png" alt="#" />
                    </span>
                  </div>
                  <h3>
                    <a href="service-details.html">Curated Products</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p>
                    Lorem ipsum dolor sit ame it, consectetur adipisicing elit,
                    sed do eiusmod te mp or incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-12">
              <div className="ltn__feature-item ltn__feature-item-7">
                <div className="ltn__feature-icon-title">
                  <div className="ltn__feature-icon">
                    <span>
                      <img src="img/icons/icon-img/23.png" alt="#" />
                    </span>
                  </div>
                  <h3>
                    <a href="service-details.html">Pesticide Free Goods</a>
                  </h3>
                </div>
                <div className="ltn__feature-info">
                  <p>
                    Lorem ipsum dolor sit ame it, consectetur adipisicing elit,
                    sed do eiusmod te mp or incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__team-area pt-115 pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2 text-center">
                <h1 className="section-title white-color---">Team Member</h1>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="ltn__team-item">
                <div className="team-img">
                  <img src="img/team/1.jpg" alt="Image" />
                </div>
                <div className="team-info">
                  <h6 className="ltn__secondary-color">// founder //</h6>
                  <h4>
                    <a href="team-details.html">Rosalina D. William</a>
                  </h4>
                  <div className="ltn__social-media">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="ltn__team-item">
                <div className="team-img">
                  <img src="img/team/2.jpg" alt="Image" />
                </div>
                <div className="team-info">
                  <h6 className="ltn__secondary-color">// founder //</h6>
                  <h4>
                    <a href="team-details.html">Rosalina D. William</a>
                  </h4>
                  <div className="ltn__social-media">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="ltn__team-item">
                <div className="team-img">
                  <img src="img/team/3.jpg" alt="Image" />
                </div>
                <div className="team-info">
                  <h6 className="ltn__secondary-color">// founder //</h6>
                  <h4>
                    <a href="team-details.html">Rosalina D. William</a>
                  </h4>
                  <div className="ltn__social-media">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-sm-6">
              <div className="ltn__team-item">
                <div className="team-img">
                  <img src="img/team/4.jpg" alt="Image" />
                </div>
                <div className="team-info">
                  <h6 className="ltn__secondary-color">// founder //</h6>
                  <h4>
                    <a href="team-details.html">Rosalina D. William</a>
                  </h4>
                  <div className="ltn__social-media">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
