import { USER } from "../constants/DataConstant";
import axios from "axios";
import { BASE_URL } from "../constants/DataConstant";
import store from "../store/store";
const ax = axios.create({ baseURL: BASE_URL });


export const calculateDiscountedAmt = (data) => {
  var discountAmt = (data.price * data.discount) / 100;
  var discountedAmt = data.price - discountAmt;
  return discountedAmt;
};

export const calculateDiscountAmt = (data) => {
  var discountAmt = (data.price * data.discount) / 100;
  return discountAmt;
};

export const createAccount = async (data) => {
  let config = {
    method: "post",
    url: `User/send`,
    headers: { "Content-Type": "application/json" },
    data: JSON.stringify(data),
  };
  const response = await ax.request(config);
  return response.data;
};

export const getUser = async (data) => {
  let config = {
    method: "get",
    url: `User`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const updateUser = async (data) => {
  let config = {
    method: "put",
    url: `User`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
    data: JSON.stringify(data),
  };
  const response = await ax.request(config);
  return response.data;
};

export const verifyAccount = async (data) => {
  let config = {
    method: "post",
    url: `User/validate`,
    headers: { "Content-Type": "application/json" },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getCatelog = async () => {
  let config = {
    method: "get",
    url: `Catelog/getCatelogV1`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${data.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const getSingleCatelog = async (pId,vId) => {
  let config = {
    method: "get",
    url: `Catelog/getCatelogV1?ProdId=${pId}&VendorId=${vId}`,
    headers: {
      "Content-Type": "application/json",
      // Authorization: `Bearer ${data.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const addToCart = async (data) => {

  let config = {
    method: "post",
    url: `Cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getCartItems = async () => {
  let config = {
    method: "get",
    url: `Cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const updateCartItem = async (data) => {
  let config = {
    method: "put",
    url: `Cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const deleteCartItem = async (data) => {
  let config = {
    method: "delete",
    url: `Cart`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const addAddress = async (data) => {
  let config = {
    method: "post",
    url: `Address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const placeOrder = async (data) => {
  let config = {
    method: "post",
    url: `Order`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
    data: data,
  };
  const response = await ax.request(config);
  return response.data;
};

export const getAddresses = async () => {
  let config = {
    method: "get",
    url: `Address`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const getAllOrders = async () => {
  let config = {
    method: "get",
    url: `Order/getOrders`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

export const cancelReturnOrderItem = async (data) => {
  let config = {
    method: "put",
    url: `Order`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
    data:data
  };
  const response = await ax.request(config);
  return response.data;
};

export const getNotifications = async (data) => {
  let config = {
    method: "get",
    url: `Notification`,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${USER.encriptedToken}`,
    },
  };
  const response = await ax.request(config);
  return response.data;
};

