// src/store/slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
  },
  reducers: {
    // Reducer for logging in the user
    login: (state, action) => {
      state.value = action.payload;
    },
    // Reducer for logging out the user
    logout: (state) => {
      state.value = null;
    },
    // Reducer for loading user data from storage
    loadUser: (state, action) => {
      const user = action.payload;
      if (user) {
        state.value = user;
      }
    },
  },
});

export const { login, logout, loadUser } = userSlice.actions;
export default userSlice.reducer;
