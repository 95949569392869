import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "../../services/Loader";
import { getUser, updateUser } from "../../services/APIService";

export default function AccountDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [userData, setUserData] = useState();

  useEffect(() => {
    loadUserData();
  }, []);

  const loadUserData = async () => {
    try {
      setIsLoading(true);
      const response = await getUser();
      setUserData(response[0]);
      reset(response[0]);
      setIsLoading(false);
    } catch (error) {
      //setIsLoading(false);
      console.log(error);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      userData.userName=data.userName;
      userData.email=data.email;
      // data.userId = 0;
      // data.slug = "string";
      // data.mobile = "string";
      // data.password = "string";
      // data.profilePic = "string";
      // data.storeAddress = "string";
      // data.status = 0;
      // data.role = 0;
      // data.googleId = "string";
      // data.facebookId = "string";
      // data.referralCode = "string";
      // data.referralAmount = 0;
      // data.wallet = "string";
      // data.token = "string";
      // data.otp = "string";
      // data.isVerified = 0;
      // data.isAvailable = 0;
      // data.facebook = "string";
      // data.instagram = "string";
      // data.twitter = "string";
      // data.google = "string";
      // data.youtube = "string";
      // data.rememberToken = "string";
      // data.createdAt = "2024-11-08T07:49:00.687Z";
      // data.updatedAt = "2024-11-08T07:49:00.687Z";
      // data.adminCommission = 0;
      // data.panNumber = "string";
      // data.gstNumber = "string";
      // data.docPath = "string";
      // data.crud = "string";
      console.log("userData---------->",userData);
      
      const response = await updateUser(userData);
      setShowMsg(true);
      setMsgClass("alert alert-success alert-dismissible fade show");
      setMsgText("Profile updated successfully..!");
      loadUserData();
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      {isLoading && <Loader />}
      <h4>Account Details</h4>
      <hr className="my-3" />
      <div className="ltn__form-box">
        {showMsg && (
          <div
            className={msgClass}
            //className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>{msgText}</strong>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {/* <span className="float-right" aria-hidden="true">
                      ×
                    </span> */}
            </button>
          </div>
        )}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-20">
            <div className="col-md-6">
              <label>Name :</label>
              <input
                type="text"
                name="ltn__name"
                placeholder="Enter name"
                {...register("userName", {
                  required: "Enter name.",
                })}
              />
              {errors?.userName && (
                <p className="text-danger">{errors?.userName.message}</p>
              )}
            </div>
            <div className="col-md-6">
              <label>Email :</label>
              <input
                type="email"
                name="ltn__lastname"
                placeholder="Enter email"
                {...register("email", {
                  required: "Enter email.",
                })}
              />
              {errors?.email && (
                <p className="text-danger">{errors?.email.message}</p>
              )}
            </div>
          </div>

          <div className="btn-wrapper">
            <button
              type="submit"
              className="btn theme-btn-1 btn-effect-1 text-uppercase"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
