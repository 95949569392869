import React, { useState, useEffect } from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../store/slices/userSlice";
import {
  getCartItems,
  calculateDiscountedAmt,
  deleteCartItem,
  getNotifications,
} from "../../services/APIService";
import Loader from "../../services/Loader";
import { loadCart, sTotal } from "../../store/slices/cartSlice";
import { loadNotification } from "../../store/slices/notificationSlice";
import { USER } from "../../constants/DataConstant";

export default function Layout() {
  const user = useSelector((state) => state.user.value);
  // let user = USER !== null;

  const cartDataRedux = useSelector((state) => state.cart.value);
  const cartsubTotalRedux = useSelector((state) => state.cart.subTotal);
  const notificationsRedux = useSelector((state) => state.notification.value);
  const notificationCount = notificationsRedux.filter(
    (item) => item.isRead == 0
  ).length;
  const dispatch = useDispatch();
  const [cartData, setCartData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [subTotal, setSubtotal] = useState(0);
  const [notificationData, setNotificationData] = useState([]);

  useEffect(() => {
    loadCartItems();
    loadNotifications();
  }, [user]);

  const loadCartItems = async () => {
    try {
      setIsLoading(true);
      const response = await getCartItems();
      setCartData(response);
      console.log("cart===>", response);

      dispatch(loadCart(response));
      let subTotal = 0;
      for (let i = 0; i < response.length; i++) {
        subTotal += calculateDiscountedAmt(response[i]) * response[i].qty;
      }
      dispatch(sTotal(subTotal));
      if (response.length > 0) checkoutCalculation(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      dispatch(loadCart([]));
      dispatch(sTotal(0));
    }
  };

  const loadNotifications = async () => {
    try {
      setIsLoading(true);
      const response = await getNotifications();
      setNotificationData(response);
      dispatch(loadNotification(response));
      console.log("Notifications==>", response);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const deleteCart = async (item) => {
    try {
        setIsLoading(true);
        var data = {
          cartId: item.cartId,
          qty: item.qty - 1,
          vendorVariantId: item.vendor_variant_id,
        };
        const response = await deleteCartItem(data);
        console.log(response);
        setCartData(response);
        loadCartItems();
        setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const checkoutCalculation = (data) => {
    var sTotal = 0;
    for (let i = 0; i < data.length; i++) {
      var total = calculateDiscountedAmt(data[i]);
      sTotal += total * data[i].qty;
    }
    setSubtotal(sTotal);
  };

  const logoutUser = () => {
      dispatch(logout());
      localStorage.removeItem("UserToken");
      window.location.reload();
  };

  return (
    <div className="body-wrapper">
      <header className="ltn__header-area ltn__header-5 ltn__header-transparent gradient-color-4---">
        <div className="ltn__header-top-area">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul>
                    <li>
                      <a href="javascript:void(0)">
                        <i className="icon-placeholder"></i> Pune, Maharashtra,
                        422 007
                      </a>
                    </li>
                    <li>
                      <a href="mailto:info@webmail.com?Subject=Flower%20greetings%20to%20you">
                        <i className="icon-mail"></i> info@atjoin.com
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top-bar-right  text-end">
                  <div className="ltn__top-bar-menu">
                    <ul>
                      <li>
                        <div className="ltn__social-media">
                          <ul>
                            <li>
                              <a href="javascript:void(0)" title="Facebook">
                                <i className="fab fa-facebook-f"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" title="Twitter">
                                <i className="fab fa-twitter"></i>
                              </a>
                            </li>

                            <li>
                              <a href="javascript:void(0)" title="Instagram">
                                <i className="fab fa-instagram"></i>
                              </a>
                            </li>
                            <li>
                              <a href="javascript:void(0)" title="Dribbble">
                                <i className="fab fa-dribbble"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="ltn__header-middle-area ltn__header-sticky ltn__sticky-bg-white ltn__logo-right-menu-option sticky-active-into-mobile--- plr--9---">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap">
                  <div className="site-logo">
                    <a href="/">
                      <img src="/img/logo.png" alt="Logo" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column menu-color-white---">
                <div className="header-menu d-none d-xl-block">
                  <nav>
                    <div className="ltn__main-menu">
                      <ul>
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <NavLink to={"/about"}>About</NavLink>
                        </li>
                        <li>
                          <a href={"/shop"}>Shop</a>
                        </li>

                        <li>
                          <NavLink to={"/contact"}>Contact</NavLink>
                        </li>
                        {user !== null ? (
                          <li className="special-link">
                            <NavLink to={"/user"}>My Account</NavLink>
                          </li>
                        ) : (
                          <li className="special-link">
                            <NavLink to={"/login"}>Login</NavLink>
                          </li>
                        )}
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="ltn__header-options ltn__header-options-2">
                <div className="header-search-wrap">
                  <div className="header-search-1">
                    <div className="search-icon">
                      <i className="icon-search for-search-show"></i>
                      <i className="icon-cancel  for-search-close"></i>
                    </div>
                  </div>
                  <div className="header-search-1-form">
                    <form id="#" method="get" action="#">
                      <input
                        type="text"
                        name="search"
                        value=""
                        placeholder="Search here..."
                      />
                      <button type="submit">
                        <span>
                          <i className="icon-search"></i>
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
                <div className="ltn__drop-menu user-menu">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="icon-user"></i>
                      </a>
                      <ul>
                        {!user && (
                          <li>
                            <Link to={"/login"}>Login / Register</Link>
                          </li>
                        )}
                        {user && (
                          <li>
                            <Link to={"/user"}>My Account</Link>
                          </li>
                        )}
                        {user && (
                          <li>
                            <Link to={"/cart"}>Cart</Link>
                          </li>
                        )}
                        {user && (
                          <li>
                            <Link to={"/wishlist"}>Wishlist</Link>
                          </li>
                        )}
                        {user && (
                          <li data-bs-toggle="modal" data-bs-target="#logout_modal">
                            <a role="button" >
                              Logout
                            </a>
                          </li>
                        )}
                      </ul>
                    </li>
                  </ul>
                </div>

                <div className="mini-cart-icon">
                  <a
                    href="#ltn__utilize-cart-menu"
                    className="ltn__utilize-toggle"
                  >
                    <i className="icon-shopping-cart"></i>
                    <sup>{cartDataRedux?.length}</sup>
                  </a>
                </div>
                <div className="mini-cart-icon">
                  <a
                    href="#ltn__utilize-notification"
                    className="ltn__utilize-toggle"
                  >
                    <i className="fa fa-bell"></i>
                    <sup>{notificationCount}</sup>
                  </a>
                </div>
                <div className="mobile-menu-toggle d-xl-none">
                  <a
                    href="#ltn__utilize-mobile-menu"
                    className="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      ></path>
                      <path d="M300,320 L540,320" id="middle"></path>
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      ></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div
        id="ltn__utilize-cart-menu"
        className="ltn__utilize ltn__utilize-cart-menu"
      >
        {cartDataRedux.length > 0 ? (
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <span className="ltn__utilize-menu-title">Cart</span>
              <button className="ltn__utilize-close">×</button>
            </div>
            <div className="mini-cart-product-area ltn__scrollbar">
              {cartDataRedux &&
                cartDataRedux.map((item, i) => (
                  <div className="mini-cart-item clearfix">
                    <div className="mini-cart-img">
                      <a href="#">
                        <img src="/img/product/1.png" alt="Image" />
                      </a>
                      <span
                        onClick={() => deleteCart(item)}
                        className="mini-cart-item-delete"
                      >
                        <i className="icon-cancel"></i>
                      </span>
                    </div>
                    <div className="mini-cart-info">
                      <h6>
                        <a href="javascript:void(0)">{item.productName}</a>
                      </h6>
                      <span className="mini-cart-quantity">
                        {item.qty} x ₹{calculateDiscountedAmt(item)}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
            <div className="mini-cart-footer">
              <div className="mini-cart-sub-total">
                <h5>
                  Subtotal: <span>₹{cartsubTotalRedux}</span>
                </h5>
              </div>
              <div className="btn-wrapper">
                <a
                  href={"/cart"}
                  className="theme-btn-1 btn btn-effect-1 w-100"
                >
                  View Cart
                </a>
                {/* <a href={"/checkout"} className="theme-btn-2 btn btn-effect-2">
                Checkout
              </a> */}
              </div>
              {/* <p>Free Shipping on All Orders Over $100!</p> */}
            </div>
          </div>
        ) : (
          <div className="ltn__utilize-menu-inner ltn__scrollbar">
            <div className="ltn__utilize-menu-head">
              <span className="ltn__utilize-menu-title">Cart</span>
              <button className="ltn__utilize-close">×</button>
            </div>
            <div className="mini-cart-product-area ltn__scrollbar">
              <div className="mini-cart-item clearfix">
                <div className="mini-cart-info">
                  <h6>
                    <a href="javascript:void(0)">Your cart is empty..!</a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="mini-cart-footer">
              <div className="btn-wrapper">
                <a
                  href={"/shop"}
                  className="theme-btn-1 btn btn-effect-1 w-100"
                >
                  Shop Now
                </a>
              </div>
            </div>
          </div>
        )}
      </div>
      <div
        id="ltn__utilize-notification"
        className="ltn__utilize ltn__utilize-cart-menu"
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <span className="ltn__utilize-menu-title">Notifications</span>
            <button className="ltn__utilize-close">×</button>
          </div>
          <div className="mini-cart-product-area ltn__scrollbar">
            {notificationsRedux &&
              notificationsRedux.map((item, i) => (
                <div className="mini-cart-item clearfix">
                  <div className="mini-cart-info">
                    <h6>
                      <a href="javascript:void(0)">{item.notice}</a>
                    </h6>
                    {/* <span className="mini-cart-quantity">
                      <a className="theme-btn-5 btn btn-effect-1 w-100">
                        Mark as read
                      </a>
                    </span> */}
                  </div>
                </div>
              ))}
          </div>
          <div className="mini-cart-footer">
            <div className="btn-wrapper">
              <a
                href={"/cart"}
                className="theme-btn-1 btn btn-effect-1 w-100 ltn__utilize-close"
              >
                Close
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ltn__utilize-mobile-menu"
        className="ltn__utilize ltn__utilize-mobile-menu"
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <a href="/">
                <img src="/img/logo.png" alt="Logo" />
              </a>
            </div>
            <button className="ltn__utilize-close">×</button>
          </div>
          <div className="ltn__utilize-menu-search-form">
            <form action="#">
              <input type="text" placeholder="Search..." />
              <button>
                <i className="fas fa-search"></i>
              </button>
            </form>
          </div>
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/about">About</a>
              </li>
              <li>
                <a href="/shop">Shop</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
          <div className="ltn__utilize-buttons ltn__utilize-buttons-2">
            <ul>
              {user !== null ? (
                <li>
                  <a href="/user" title="My Account">
                    <span className="utilize-btn-icon">
                      <i className="far fa-user"></i>
                    </span>
                    My Account
                  </a>
                </li>
              ) : (
                <li>
                  <a href="/login" title="My Account">
                    <span className="utilize-btn-icon">
                      <i className="far fa-user"></i>
                    </span>
                    Login
                  </a>
                </li>
              )}
            </ul>
          </div>
          <div className="ltn__social-media-2">
            <ul>
              <li>
                <a href="#" title="Facebook">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" title="Twitter">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" title="Linkedin">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="#" title="Instagram">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ltn__utilize-overlay"></div>
      <Outlet />
      <footer className="ltn__footer-area  ">
        <div className="footer-top-area  section-bg-1 plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-3 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-about-widget">
                  <div className="footer-logo">
                    <div className="site-logo">
                      <img src="/img/logo.png" alt="Logo" />
                    </div>
                  </div>
                  <p>
                    Lorem Ipsum is simply dummy text of the and typesetting
                    industry. Lorem Ipsum is dummy text of the printing.
                  </p>
                  <div className="footer-address">
                    <ul>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-placeholder"></i>
                        </div>
                        <div className="footer-address-info">
                          <p>Pune, Maharashtra, 422 007</p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-call"></i>
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="tel:+0123-456789">+91 8275 5822 15</a>
                          </p>
                        </div>
                      </li>
                      <li>
                        <div className="footer-address-icon">
                          <i className="icon-mail"></i>
                        </div>
                        <div className="footer-address-info">
                          <p>
                            <a href="mailto:example@example.com">
                              info@atjoin.com
                            </a>
                          </p>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="ltn__social-media mt-20">
                    <ul>
                      <li>
                        <a href="#" title="Facebook">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Twitter">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Linkedin">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#" title="Youtube">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Company</h4>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="about.html">About</a>
                      </li>
                      <li>
                        <a href="blog.html">Blog</a>
                      </li>
                      <li>
                        <a href="shop.html">All Products</a>
                      </li>
                      <li>
                        <a href="locations.html">Locations Map</a>
                      </li>
                      <li>
                        <a href="faq.html">FAQ</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Services.</h4>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="order-tracking.html">Order tracking</a>
                      </li>
                      <li>
                        <a href="wishlist.html">Wish List</a>
                      </li>
                      <li>
                        <a href="login.html">Login</a>
                      </li>
                      <li>
                        <a href="account.html">My account</a>
                      </li>
                      <li>
                        <a href="about.html">Terms & Conditions</a>
                      </li>
                      <li>
                        <a href="about.html">Promotional Offers</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-md-6 col-sm-6 col-12">
                <div className="footer-widget footer-menu-widget clearfix">
                  <h4 className="footer-title">Customer Care</h4>
                  <div className="footer-menu">
                    <ul>
                      <li>
                        <a href="login.html">Login</a>
                      </li>
                      <li>
                        <a href="account.html">My account</a>
                      </li>
                      <li>
                        <a href="wishlist.html">Wish List</a>
                      </li>
                      <li>
                        <a href="order-tracking.html">Order tracking</a>
                      </li>
                      <li>
                        <a href="faq.html">FAQ</a>
                      </li>
                      <li>
                        <a href="contact.html">Contact us</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                <div className="footer-widget footer-newsletter-widget">
                  <h4 className="footer-title">Newsletter</h4>
                  <p>
                    Subscribe to our weekly Newsletter and receive updates via
                    email.
                  </p>
                  <div className="footer-newsletter">
                    <div id="mc_embed_signup">
                      <form
                        action="https://gmail.us5.list-manage.com/subscribe/post?u=dde0a42ff09e8d43cad40dc82&amp;id=72d274d15d"
                        method="post"
                        id="mc-embedded-subscribe-form"
                        name="mc-embedded-subscribe-form"
                        className="validate"
                        target="_blank"
                        novalidate
                      >
                        <div id="mc_embed_signup_scroll">
                          <div className="mc-field-group">
                            <input
                              type="email"
                              value=""
                              name="EMAIL"
                              className="required email"
                              id="mce-EMAIL"
                              placeholder="Email*"
                            />
                          </div>
                          <div id="mce-responses" className="clear">
                            <div
                              className="response"
                              id="mce-error-response"
                              style={{ display: "none" }}
                            ></div>
                            <div
                              className="response"
                              id="mce-success-response"
                              style={{ display: "none" }}
                            ></div>
                          </div>
                          <div
                            style={{ position: "absolute", left: "-5000px" }}
                            aria-hidden="true"
                          >
                            <input
                              type="text"
                              name="b_dde0a42ff09e8d43cad40dc82_72d274d15d"
                              tabindex="-1"
                              value=""
                            />
                          </div>
                          <div className="clear">
                            <div className="btn-wrapper">
                              <button
                                className="theme-btn-1 btn"
                                type="submit"
                                value="Subscribe"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                              >
                                <i className="fas fa-location-arrow"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  <h5 className="mt-30">We Accept</h5>
                  <img src="/img/icons/payment-4.png" alt="Payment Image" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ltn__copyright-area ltn__copyright-2 section-bg-2  ltn__border-top-2--- plr--5">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-6 col-12">
                <div className="ltn__copyright-design clearfix">
                  <p>
                    All Rights Reserved @ Inspire Business{" "}
                    <span className="current-year"></span>
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-12 align-self-center">
                <div className="ltn__copyright-menu text-end">
                  <ul>
                    <li>
                      <a href="#">Terms & Conditions</a>
                    </li>
                    <li>
                      <a href="#">Claim</a>
                    </li>
                    <li>
                      <a href="#">Privacy & Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      {/* modal start logout */}
      <div class="ltn__modal-area ltn__add-to-cart-modal-area">
        <div class="modal fade" id="logout_modal" tabindex="-1">
            <div class="modal-dialog modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                         <div class="ltn__quick-view-modal-inner">
                             <div class="modal-product-item">
                                <div class="row">
                                    <div class="col-12">
                                        {/* <div class="modal-product-img">
                                            <img src="img/product/1.png" alt="#"/>
                                        </div> */}
                                         <div class="modal-product-info">
                                            <h5><a href="product-details.html">Confirmation</a></h5>
                                            <p class="added-cart"><i class="fa fa-check-circle"></i>  Do you want to logout..?</p>
                                            <div class="btn-wrapper">
                                                <a href="javascript:void(0);" onClick={logoutUser} class="theme-btn-1 btn btn-effect-1">Logout</a>
                                                <a href="javascript:void(0);" class="theme-btn-2 btn btn-effect-2" data-bs-dismiss="modal" aria-label="Close">Cancel</a>
                                            </div>
                                         </div>
                                         <div class="additional-info d-none">
                                            <p>We want to give you <b>10% discount</b> for your first order, <br/>  Use discount code at checkout</p>
                                            <div class="payment-method">
                                                <img src="img/icons/payment.png" alt="#"/>
                                            </div>
                                         </div>
                                    </div>
                                </div>
                             </div>
                         </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    </div>
  );
}
