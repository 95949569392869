import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../services/Loader";
import { getAddresses } from "../../services/APIService";

export default function Addresses() {
  const [isLoading, setIsLoading] = useState(false);
  const [addressData, setAddress] = useState([]);

  useEffect(() => {
    loadAddress();
  }, []);

  const loadAddress = async () => {
    try {
      setIsLoading(true);
      const response = await getAddresses();
      console.log(response);
      setAddress(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      {/* <p>
        The following addresses will be used on the checkout page by default.
      </p> */}
      <div className="row">
        <div className="col-md-12 col-12 learts-mb-30">
          <h4>Billing Address</h4>
          <hr className="my-3"/>
          <address>
            {addressData &&
              addressData.map((item, i) => (
                <p>
                 {item.streetAddress},{" "}
                  {item.landmark}, {item.pincode}
                  {/* <strong>Alex Tuntuni</strong> */}
                </p>
              ))}

            {/* <p>
              1355 Market St, Suite 900 <br />
              San Francisco, CA 94103
            </p>
            <p>Mobile: (123) 456-7890</p> */}
          </address>
        </div>
        {/* <div className="col-md-6 col-12 learts-mb-30">
          <h4>
            Shipping Address{" "}
            <small>
              <a href="#">edit</a>
            </small>
          </h4>
          <address>
            <p>
              <strong>Alex Tuntuni</strong>
            </p>
            <p>
              1355 Market St, Suite 900 <br />
              San Francisco, CA 94103
            </p>
            <p>Mobile: (123) 456-7890</p>
          </address>
        </div> */}
      </div>
    </div>
  );
}
