import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getCatelog,
  calculateDiscountedAmt,
  addToCart,
} from "../../services/APIService";
import Loader from "../../services/Loader";
import { useSelector, useDispatch } from "react-redux";

export default function Home() {
  const navigate = useNavigate();
  const userToken = useSelector((state) => state.user.value);

  const [isLoading, setIsLoading] = useState(false);
  const [productData, setProductData] = useState();

  useEffect(() => {
    loadProducts();
  }, []);

  const loadProducts = async () => {
    try {
      setIsLoading(true);
      const response = await getCatelog();
      setProductData(response.products);
      console.log(response);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const productDetails = (item) => {
    navigate(`/product-details/${item.prodId}/${item.variants[0].vendorVariants[0].vendorId}`);
    window.location.reload();
  };

  const fillCart = async (item) => {
    try {
      if (userToken) {
        setIsLoading(true);
        var data = {
          cartId: 0,
          qty: 1,
          vendorVariantId: item?.variants[0]?.vendorVariants[0]?.idVendorVariant,
          // vendorVariantId: item?.vendorVariant?.idVendorVariant,
          crud: "string",
        };
        console.log(data);
        
        const response = await addToCart(data);
        navigate("/cart");
        setIsLoading(false);
      } else {
        navigate("/login");
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  return (
    <div>
      {isLoading && <Loader />}
      <div className="ltn__slider-area ltn__slider-3  section-bg-1">
        <div className="ltn__slide-one-active slick-slide-arrow-1 slick-slide-dots-1">
          <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3">
            <div className="ltn__slide-item-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <div className="slide-video mb-50 d-none">
                          <a
                            className="ltn__video-icon-2 ltn__video-icon-2-border"
                            href="https://www.youtube.com/embed/ATI7vfCgwXE?autoplay=1&amp;showinfo=0"
                            data-rel="lightcase:myCollection"
                          >
                            <i className="fa fa-play"></i>
                          </a>
                        </div>
                        <h6 className="slide-sub-title animated">
                          <img src="img/icons/icon-img/1.png" alt="#" /> 100%
                          genuine Products
                        </h6>
                        <h1 className="slide-title animated ">
                          Tasty & Healthy <br /> Organic Food
                        </h1>
                        <div className="slide-brief animated d-none">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore.
                          </p>
                        </div>
                        <div className="btn-wrapper animated">
                          <a
                            href="shop.html"
                            className="theme-btn-1 btn btn-effect-1 text-uppercase"
                          >
                            Explore Products
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="slide-item-img">
                      <img src="img/slider/23.png" alt="#" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-3">
            <div className="ltn__slide-item-inner  text-right text-end">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 align-self-center">
                    <div className="slide-item-info">
                      <div className="slide-item-info-inner ltn__slide-animation">
                        <h6 className="slide-sub-title animated">
                          <img src="img/icons/icon-img/1.png" alt="#" /> 100%
                          genuine Products
                        </h6>
                        <h1 className="slide-title animated ">
                          Our Garden's Most <br /> Favorite Food
                        </h1>
                        <div className="slide-brief animated">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit, sed do eiusmod tempor incididunt ut labore.
                          </p>
                        </div>
                        <div className="btn-wrapper animated">
                          <a
                            href="shop.html"
                            className="theme-btn-1 btn btn-effect-1 text-uppercase"
                          >
                            Explore Products
                          </a>
                          <a
                            href="about.html"
                            className="btn btn-transparent btn-effect-3 text-uppercase"
                          >
                            LEARN MORE
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="slide-item-img slide-img-left">
                      <img src="img/slider/21.png" alt="#" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__banner-area mt-120 mt--90 d-none">
        <div className="container">
          <div className="row ltn__custom-gutter--- justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="ltn__banner-item">
                <div className="ltn__banner-img">
                  <a href="shop.html">
                    <img src="img/banner/1.jpg" alt="Banner Image" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ltn__banner-item">
                <div className="ltn__banner-img">
                  <a href="shop.html">
                    <img src="img/banner/2.jpg" alt="Banner Image" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="ltn__banner-item">
                <div className="ltn__banner-img">
                  <a href="shop.html">
                    <img src="img/banner/1.jpg" alt="Banner Image" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="ltn__product-tab-area ltn__product-gutter pt-85 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title-area ltn__section-title-2 text-center">
                <h1 className="section-title">Our Products</h1>
              </div>

              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      {/* <!-- ltn__product-item --> */}
                      {productData &&
                        productData.map((item, i) => (
                          <div className="col-xl-3 col-lg-4 col-sm-6 col-6">
                            <div className="ltn__product-item ltn__product-item-3 text-center">
                              <div className="product-img">
                                <a
                                  role="button"
                                  onClick={() => productDetails(item)}
                                  href="javascript:void(0)"
                                >
                                  <img src={item?.variants[0]?.images[0]?.image} alt="#" />
                                </a>
                                {item.isHot && (
                                  <div className="product-badge">
                                    <ul>
                                      <li className="sale-badge">Hot</li>
                                    </ul>
                                  </div>
                                )}

                                <div className="product-hover-action">
                                  <ul>
                                    <li>
                                      <a
                                        onClick={() => productDetails(item)}
                                        role="button"
                                        title="Quick View"
                                        data-bs-toggle="modal"
                                        data-bs-target="#quick_view_modal"
                                      >
                                        <i className="far fa-eye"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        onClick={() => fillCart(item)}
                                        href="javascript:void(0);"
                                        title="Add to Cart"
                                        data-bs-toggle="modal"
                                        data-bs-target="#add_to_cart_modal"
                                      >
                                        <i className="fas fa-shopping-cart"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a
                                        href="#"
                                        title="Wishlist"
                                        data-bs-toggle="modal"
                                        data-bs-target="#liton_wishlist_modal"
                                      >
                                        <i className="far fa-heart"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div className="product-info">
                                <div className="product-ratting">
                                  <ul>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="fas fa-star-half-alt"></i>
                                      </a>
                                    </li>
                                    <li>
                                      <a href="#">
                                        <i className="far fa-star"></i>
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                                <h2 className="product-title">
                                  <a href="product-details.html">
                                    {item.productName}
                                  </a>
                                </h2>
                                <div className="product-price">
                                  {item?.variants[0].vendorVariants[0] && (
                                    <>
                                      <span>
                                        ₹
                                        {calculateDiscountedAmt(
                                          item?.variants[0].vendorVariants[0]
                                        )}
                                      </span>
                                      <del>₹{item?.variants[0].vendorVariants[0].price}</del>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}

                      {/* <div className="col-xl-3 col-lg-4 col-sm-6 col-6">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/2.png" alt="#" />
                            </a>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Vegetables Juices
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$62.00</span>
                              <del>$85.00</del>
                            </div>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="ltn__tab-menu ltn__tab-menu-2 ltn__tab-menu-top-right-- text-uppercase text-center">
                <div className="nav">
                  <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_1"
                  >
                    Food & Drinks
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_2" className="">
                    Vegetables
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_3" className="">
                    Dried Foods
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_4" className="">
                    Bread & Cake
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_5" className="">
                    Fish & Meat
                  </a>
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade active show" id="liton_tab_3_1">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/11.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/7.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Poltry Farm Meat
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$78.00</span>
                              <del>$85.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/12.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/8.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Orange Sliced Mix
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$150.00</span>
                              <del>$180.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/13.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Orange Fresh Juice
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$75.00</span>
                              <del>$92.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/9.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/14.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Poltry Farm Meat
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$78.00</span>
                              <del>$85.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/10.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Orange Fresh Juice
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$75.00</span>
                              <del>$92.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_tab_3_2">
                  <div className="ltn__product-tab-content-inner">
                    <div className="row ltn__tab-product-slider-one-active slick-arrow-1">
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/16.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/10.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Poltry Farm Meat
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$78.00</span>
                              <del>$85.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/15.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/9.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Orange Sliced Mix
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$150.00</span>
                              <del>$180.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/14.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Orange Fresh Juice
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$75.00</span>
                              <del>$92.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/8.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/13.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Poltry Farm Meat
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$78.00</span>
                              <del>$85.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/10.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Orange Fresh Juice
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$75.00</span>
                              <del>$92.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/15.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Fresh Butter Cake
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$150.00</span>
                              <del>$180.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/6.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/7.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">New</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Orange Sliced Mix
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$150.00</span>
                              <del>$180.00</del>
                            </div>
                          </div>
                        </div>
                        <div className="ltn__product-item ltn__product-item-3 text-center">
                          <div className="product-img">
                            <a href="product-details.html">
                              <img src="img/product/11.png" alt="#" />
                            </a>
                            <div className="product-badge">
                              <ul>
                                <li className="sale-badge">-19%</li>
                              </ul>
                            </div>
                            <div className="product-hover-action">
                              <ul>
                                <li>
                                  <a
                                    href="#"
                                    title="Quick View"
                                    data-bs-toggle="modal"
                                    data-bs-target="#quick_view_modal"
                                  >
                                    <i className="far fa-eye"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Add to Cart"
                                    data-bs-toggle="modal"
                                    data-bs-target="#add_to_cart_modal"
                                  >
                                    <i className="fas fa-shopping-cart"></i>
                                  </a>
                                </li>
                                <li>
                                  <a
                                    href="#"
                                    title="Wishlist"
                                    data-bs-toggle="modal"
                                    data-bs-target="#liton_wishlist_modal"
                                  >
                                    <i className="far fa-heart"></i>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="product-info">
                            <div className="product-ratting">
                              <ul>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="fas fa-star-half-alt"></i>
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    <i className="far fa-star"></i>
                                  </a>
                                </li>
                                <li className="review-total">
                                  {" "}
                                  <a href="#"> (24)</a>
                                </li>
                              </ul>
                            </div>
                            <h2 className="product-title">
                              <a href="product-details.html">
                                Carrots Group Scal
                              </a>
                            </h2>
                            <div className="product-price">
                              <span>$32.00</span>
                              <del>$46.00</del>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
