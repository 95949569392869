import React from "react";

export default function Loader() {
  return (
    <div class="preloader" id="preloader">
      <div class="preloader-inner">
        <div class="spinner">
          <div class="dot1"></div>
          <div class="dot2"></div>
        </div>
      </div>
    </div>
  );
}
// import React from "react";

// export default function Loader() {
//   return (
//     <div class="spinner-overlay" id="spinnerOverlay">
//       <div class="d-flex justify-content-center">
//         <div class="spinner-border" role="status">
//           <span class="dashboard-spinner spinner-xs"></span>
//         </div>
//       </div>
//     </div>
//   );
// }
