// src/store/slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: {
    value: [],
    subTotal: 0,
  },
  reducers: {
    loadCart: (state, action) => {
      const user = action.payload;
      if (user) {
        state.value = user;
      }
    },
    sTotal: (state, action) => {
      const total = action.payload;
      if (total) {
        state.subTotal = total;
      }
    },
  },
});

export const { loadCart, sTotal } = cartSlice.actions;
export default cartSlice.reducer;
