import React, { useState, useEffect } from "react";
import { getAllOrders, cancelReturnOrderItem } from "../../services/APIService";
import Loader from "../../services/Loader";

export default function Orders() {
  const [isLoading, setIsLoading] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msgClass, setMsgClass] = useState("");
  const [msgText, setMsgText] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [orderDetails, setOrderDetails] = useState();
  const [subTotal, setSubtotal] = useState(0);
  const [netTotal, setNetTotal] = useState(0);

  useEffect(() => {
    loadOrderData();
  }, []);

  const discountedAmt = (data) => {
    var discountAmt = (data.price * data.discountAmount) / 100;
    var discountedAmt = data.price - discountAmt;
    return discountedAmt;
  };

  const discountAmt = (data) => {
    var discountAmt = (data.price * data.discountAmount) / 100;
    return discountAmt;
  };

  const DetailsCalculation = (data) => {
    let oDetails = data?.orderDetails;
    var sTotal = 0;
    for (let i = 0; i < oDetails?.length; i++) {
      if(oDetails[i].status!="ODC"){
        var total = discountedAmt(oDetails[i]);
        sTotal += total * oDetails[i].qty;
      }
     
    }
    setSubtotal(sTotal);
    setNetTotal(sTotal + data.shippingCost);
  };

  const loadOrderData = async () => {
    try {
      setIsLoading(true);
      const response = await getAllOrders();
      console.log(response);
      setOrderData(response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const loadOrderDetails = async (item) => {
    try {
      DetailsCalculation(item);
      console.log("item select order=>", item);
      setOrderDetails(item);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelOrder = async (id, item1) => {
    try {
      setIsLoading(true);
      const updatedItem = {
        orderDtlId: item1.orderDtlId,
        vendorId: item1.vendorId,
        productId: item1.productId,
        qty: item1.qty,
        price: item1.price,
        variantId: item1.variantId,
        tax: item1.tax,
        couponName: item1.couponName,
        discountAmount: item1.discountAmount,
        shippingCost: item1.shippingCost,
        createdAt: item1.createdAt,
        updatedAt: item1.updatedAt,
        orderId: item1.orderId,
        status: "ODC",
        crud: "U",
        orderDetailStatus: item1.orderDetailStatus,
        productName: item1.productName,
        vendorName: item1.vendorName,
        variation: item1.variation,
        vendorVariantId: item1.vendorVariantId,
      };

      const updatedProduct = orderDetails.orderDetails.map((item) =>
        item.orderDtlId === id ? { ...item, ...updatedItem } : item
      );
      var orderData = orderDetails;
      orderData.orderDetails = updatedProduct;
      const response = await cancelReturnOrderItem(orderData);
      console.log("response=>", response);

      if (response.orderId) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText("Product order cancelled successfully..!");
        loadOrderData();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };
  const returnOrder = async (id, item1) => {
    try {
      setIsLoading(true);
      const updatedItem = {
        orderDtlId: item1.orderDtlId,
        vendorId: item1.vendorId,
        productId: item1.productId,
        qty: item1.qty,
        price: item1.price,
        variantId: item1.variantId,
        tax: item1.tax,
        couponName: item1.couponName,
        discountAmount: item1.discountAmount,
        shippingCost: item1.shippingCost,
        createdAt: item1.createdAt,
        updatedAt: item1.updatedAt,
        orderId: item1.orderId,
        status: "ODI",
        crud: "U",
        orderDetailStatus: item1.orderDetailStatus,
        productName: item1.productName,
        vendorName: item1.vendorName,
        variation: item1.variation,
        vendorVariantId: item1.vendorVariantId,
      };

      const updatedProduct = orderDetails.orderDetails.map((item) =>
        item.orderDtlId === id ? { ...item, ...updatedItem } : item
      );
      var orderData = orderDetails;
      orderData.orderDetails = updatedProduct;
      const response = await cancelReturnOrderItem(orderData);
      console.log("response=>", response);

      if (response.orderId) {
        setShowMsg(true);
        setMsgClass("alert alert-success alert-dismissible fade show");
        setMsgText("Product return initiated successfully..!");
        loadOrderData();
      } else {
        setShowMsg(true);
        setMsgClass("alert alert-danger alert-dismissible fade show");
        setMsgText(response.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      setShowMsg(true);
      setMsgClass("alert alert-danger alert-dismissible fade show");
      setMsgText("Something went wrong..!");
    }
  };

  return (
    <div className="ltn__myaccount-tab-content-inner">
      {isLoading && <Loader />}

      <div className="table-responsive">
        <h4>All Orders</h4>
        {showMsg && (
          <div
            className={msgClass}
            //className="alert alert-success alert-dismissible fade show"
            role="alert"
          >
            <strong>{msgText}</strong>
            <button
              type="button"
              className="close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {/* <span className="float-right" aria-hidden="true">
                      ×
                    </span> */}
            </button>
          </div>
        )}
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Order ID</th>
              <th>Order No</th>
              <th>Payment Mode</th>
              <th>Order Status</th>
              {/* <th>Total</th> */}
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {orderData &&
              orderData.map((item, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>{item.orderId}</td>
                  <td>{item.orderNumber}</td>
                  <td>{item.paymentMethode}</td>
                  <td>{item.orderStatus}</td>
                  {/* <td>
                    {item.orderDetails
                      .map((item1) => item1.price)
                      .reduce((acc, curr) => acc + curr, 0)}
                  </td> */}

                  <td>
                    <a
                      class="theme-btn-1 btn btn-effect py-0 px-3"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#quick_view_modal"
                      onClick={() => loadOrderDetails(item)}
                    >
                      View Details
                    </a>
                    {/* <a
                      class="theme-btn-4 btn btn-danger py-0 px-3"
                      href="javascript:void(0)"
                      data-bs-toggle="modal"
                      data-bs-target="#quick_view_modal"
                      onClick={() => loadOrderDetails(item)}
                    >
                      Cancel
                    </a> */}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <div class="ltn__modal-area ltn__quick-view-modal-area">
        <div class="modal fade" id="quick_view_modal" tabindex="-1">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                  {/* <!-- <i class="fas fa-times"></i> --> */}
                </button>
              </div>
              <div class="modal-body">
                <div class="ltn__quick-view-modal-inner">
                  <div class="modal-product-item">
                    <div class="row">
                      <div class="col-lg-12 col-12">
                        <div class="modal-product-info">
                          <h3>Order Details : #{orderDetails?.orderId}</h3>

                          <table className="table">
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Product Name</th>
                                <th>Status</th>
                                <th>Qty</th>
                                <th>Discount</th>
                                <th>Price</th>
                                <th>Total</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {orderDetails?.orderDetails &&
                                orderDetails?.orderDetails.map((item, i) => (
                                  <tr>
                                    <td>{i + 1}</td>
                                    <td>{item.productName}</td>
                                    <td>{item.orderDetailStatus}</td>
                                    <td>{item.qty}</td>
                                    <td>
                                      ({item.discountAmount}%)- ₹ {discountAmt(item)}
                                    </td>
                                    <td>₹ {discountedAmt(item)}</td>
                                    <td>₹ {discountedAmt(item) * item.qty}</td>
                                    <td>
                                      {/* <a
                                        class="theme-btn-4 btn btn-success py-0 px-3"
                                        href="javascript:void(0)"
                                        data-bs-toggle="modal"
                                        data-bs-target="#quick_view_modal"
                                        onClick={() => loadOrderDetails(item)}
                                      >
                                        Return
                                      </a> */}
                                      {item.status === "ODP" && (
                                        <a
                                          class="theme-btn-4 btn btn-danger py-0 px-3"
                                          href="javascript:void(0)"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick_view_modal"
                                          onClick={() =>
                                            cancelOrder(item.orderDtlId, item)
                                          }
                                        >
                                          Cancel
                                        </a>
                                      )}

                                      {item.status == "ODD" && (
                                        <a
                                          class="theme-btn-4 btn btn-danger py-0 px-3"
                                          href="javascript:void(0)"
                                          data-bs-toggle="modal"
                                          data-bs-target="#quick_view_modal"
                                          onClick={() =>
                                            returnOrder(item.orderDtlId, item)
                                          }
                                        >
                                          Return
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                          <hr />
                          <h3>Sub Total : ₹{subTotal}</h3>
                          <h3>
                            Shipping Charges : ₹{orderDetails?.shippingCost}
                          </h3>
                          <div class="product-price">
                            <span>Net Total : ₹{netTotal}</span>
                            {/* <del>$165.00</del> */}
                          </div>
                          {/* <div class="ltn__product-details-menu-2 ">
                            <a
                              href="#"
                              class="theme-btn-1 btn btn-effect-1 float-right"
                              title="Add to Cart"
                              data-bs-toggle="modal"
                              data-bs-target="#add_to_cart_modal"
                            >
                              <i class="fa fa-times-circle-o"></i>
                              <span>Close</span>
                            </a>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
