// src/components/Counter.js
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  increment,
  decrement,
  incrementByAmount,
} from "../../store/slices/counterSlice";

function Counter() {
  const count = useSelector((state) => state.counter.value);
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  return (
    <div className="my-5">
      <h1>{count}</h1>
      <button onClick={() => dispatch(increment())}>Increment</button>
      <button onClick={() => dispatch(decrement())}>Decrement</button>
      <button onClick={() => dispatch(incrementByAmount(50))}>
        incrementByAmount
      </button>
      {/* <div>
        {user.userId} | | {user.name}
      </div> */}
    </div>
  );
}

export default Counter;
