import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { USER } from "../constants/DataConstant";

// comman comps
import Layout from "../comps/comman/Layout";
import Home from "../comps/comman/Home";
import Contact from "../comps/comman/Contact";
import About from "../comps/comman/About";
import Products from "../comps/comman/Products";
import ProductsDetails from "../comps/comman/ProductsDetails";
import Login from "../comps/comman/Login";
import Register from "../comps/comman/Register";
import Cart from "../comps/comman/Cart";
import Wishlist from "../comps/comman/Wishlist";
import Checkout from "../comps/comman/Checkout";
import Counter from "../comps/comman/Counter";
import OrderStatus from "../comps/comman/OrderStatus";

// users comps
import User from "../comps/user/User";
import Default from "../comps/user/Default";

export default function Router() {
  let user = USER !== null;
  //  const user = useSelector((state) => state.user.value);

  return (
    <BrowserRouter>
      <Routes>
        {/* visitor routes */}
        <Route path="" element={<Layout />}>
          <Route path="" element={<Home />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="shop" element={<Products />} />
          <Route path="product-details/:pId/:vId" element={<ProductsDetails />} />

          <Route path="counter" element={<Counter />} />
          <Route path="order-status" element={<OrderStatus />} />

          {/* user routes area */}
          <Route
            path="cart"
            element={user ? <Cart /> : <Navigate to="/login" />}
          />
          <Route
            path="wishlist"
            element={user ? <Wishlist /> : <Navigate to="/login" />}
          />
          <Route
            path="checkout"
            element={user ? <Checkout /> : <Navigate to="/login" />}
          />
          <Route
            path="user"
            element={user ? <User /> : <Navigate to="/login" />}
          >
            <Route path="" element={<Default />} />
            {/* <Route path="orders" element={<Default />} /> */}
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
