// src/store/slices/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    value: []
  },
  reducers: {
    loadNotification: (state, action) => {
      const user = action.payload;
      if (user) {
        state.value = user;
      }
    }
  },
});

export const { loadNotification } = notificationSlice.actions;
export default notificationSlice.reducer;
