// import React, { useEffect } from "react";
// import logo from "./logo.svg";
// import "./App.css";
// import Router from "./router/Router";
// import { useSelector, useDispatch } from "react-redux";
// import { loadUser } from "./store/slices/userSlice";

// function App() {
//   const dispatch = useDispatch();
//   // Load user data from localStorage when the app starts
//   useEffect(() => {
//     const storedUser = JSON.parse(localStorage.getItem("UserToken"));
//     console.log(storedUser);

//     if (storedUser) {
//       dispatch(loadUser(storedUser));
//     }
//   }, [dispatch]);
//   return <Router />;
// }

// export default App;

import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import "./App.css";
import Router from "./router/Router";
import { useSelector, useDispatch } from "react-redux";
import { loadUser } from "./store/slices/userSlice";
import Loader from "./services/Loader";

function App() {
  const dispatch = useDispatch();
  const [isLoginDataUpdated, setIsLoginDataUpdated] = useState(false);
  useEffect(() => {
    assignUser();
  }, [dispatch]);

  const assignUser = async () => {
    const storedUser = await JSON.parse(localStorage.getItem("UserToken"));
    console.log(JSON.stringify(storedUser));

    if (storedUser) {
      dispatch(loadUser(storedUser));
    }
    setIsLoginDataUpdated(true);
  };
  return <Router />;
  // return <>{isLoginDataUpdated && <Router />}</>;
}

export default App;
